.dialogo_consulta-publica {
  text-align: center;

  .dialogo__corpo {
    padding: 24px;
  }

  .dialogo__icone {
    margin: 0 0 16px;
    color: $consulta-cor-primaria;
    font-size: 8px;
  }
  
  .dialogo__titulo {
    color: $consulta-cor-primaria;
    line-height: 1.3;
  }

  .dialogo__mensagem {
    margin: 16px 0 0;
    color: $cor-cinza-80;
  }

  .dialogo__rodape {
    justify-content: center;
  }
}