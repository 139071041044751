.rodape {
    margin-top: auto;
    background: $cor-cinza-30;
    padding: 24px 0;
}

.rodape__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
}

.rodape__link {
    display: inline-flex;
    align-items: center;
}