.pagina-em-branco {
  box-sizing: border-box;
  padding: 48px 0;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include quebra-maior-que-telefone {
    min-height: 300px;
  }	
}

.pagina-em-branco__icone {
  color: $cor-cinza-40;
  
  svg {
    width: 40px !important;
    height: 40px;
  }
}

.pagina-em-branco__mensagem {
  @include fonte-regular;
  margin-top: 16px;
  color: $cor-cinza-50;
  font-size: 15px;
  text-align: center;
  line-height: 1.4;

  @include quebra-maior-que-telefone {
    font-size: 16px;
  }	
}