.pagina {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: $cor-cinza-20;
}

.pagina__corpo {
  padding: 24px 0;

  @include quebra-maior-que-telefone {
    padding: 32px 0;
  }
}

.pagina__formulario {
  padding: 24px 0 14px;

  legend {
    color: $cor-cinza-80;
    margin: 0;
  }

  @include quebra-somente-telefone {
    .botao {
      display: block;
      width: 100%;
    }
  }

  @include quebra-maior-que-telefone {
    .grade .grade__linha {
      flex-direction: row;
    }
  }
}

.pagina__titulo {
  @include fonte-regular;
  color: $cor-cinza-80;

  @include quebra-somente-telefone {
    font-size: 20px;
  }
}

.pagina__container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}
