.lista {
  margin: 0;
  padding: 0;
  list-style: none;
}

.lista__item {
  + .lista__item {
    margin-top: 8px;
  }
}