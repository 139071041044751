.cabecalho {
    background: $cor-terciaria;
    padding: 12px 0;
}

.cabecalho__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
}

.cabecalho__logo-agehab {
    width: 130px;
    height: 50px;

    @include quebra-menor-que-tablet {
        width: 90px;
        height: 30px;    
    }
}

.cabecalho__logo-seinfra {
    width: 85px;
    height: 35px;

    @include quebra-menor-que-tablet {
        width: 60px;
        height: 25px;
    }
}

.cabecalho__logo-estado {
    width: 143px;
    height: 50px;

    @include quebra-menor-que-tablet {
        width: 88px;
        height: 30px;    
    }
}