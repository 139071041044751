// Estilos espefícios para o projeto

.cartao {
  @include quebra-maior-que-tablet {
    .u-mostrar-apenas-no-tablet {
      display: none;
    }
  }
}

.cartao_com-destaque {
  .cartao__cabecalho:before {
    flex: 0 0 4px;
  }
}

.cartao_com-cabecalho-centralizado-no-celular {
  .cartao__container-primario {
    @include quebra-menor-que-tablet {
      align-self: stretch;
      text-align: left;
    }
  }

  .cartao__container-secundario {
    @include quebra-somente-telefone {
      align-self: stretch;
    }
  }
}

.cartao__titulo {
  text-transform: uppercase;
}

.cartao__subtitulo {
  color: $cor-cinza-90;

  @include quebra-menor-que-tablet {
    display: none;
  }
}

.cartao__texto-grupo {
  @include quebra-maior-que-telefone {
    display: flex;

    .cartao__texto {
      margin: 0 16px 0 0;
    }
  }
}

.cartao__texto {
  margin: 0 0 16px;
}

.cartao__subtitulo-item_lista {
  font-weight: 600;
}

.cartao__lista {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cartao__item {
  + .cartao__item {
    margin-top: 16px;
  }
}

.cartao__nome {
  color: $cor-cinza-90;
}

.cartao__classificacao {
  margin-right: 4px;
}

.cartao__subtitulo {
  display: flex;
  flex-direction: column;

  @include quebra-maior-que-tablet {
    flex-direction: row;
  }
}

.cartao__subtitulo-item {
  &.cartao__subtitulo-item_sem-quebra-de-linha {
    white-space: nowrap;
  }

  + .cartao__subtitulo-item {
    margin-top: 8px;
  }

  @include quebra-maior-que-tablet {
    + .cartao__subtitulo-item {
      margin: 0 0 0 16px;
    }
  }
}

.cartao_cor-principal {
  color: $consulta-cor-primaria;

  .botao {
    @extend .botao_cor-sucesso;
    
    &.botao_contorno {
      color: $consulta-cor-primaria;
      border-color: rgba($consulta-cor-primaria, 0.6);

      &:hover,
      &:focus {
        border-color: $consulta-cor-primaria;
      }
    }
  }

  .cartao__cabecalho:before {
    background: $consulta-cor-primaria;
  }

  .cartao__classificacao {
    color: $consulta-cor-primaria;
  }

  .cartao__subtitulo-item_lista {
    color: $consulta-cor-primaria;
  }
}

.cartao_cor-reserva {
  color: $cor-info;

  .botao {
    @extend .botao_cor-info;
  }

  .cartao__cabecalho:before {
    background: $cor-info;
  }

  .cartao__classificacao {
    color: $cor-info;
  }

  .cartao__subtitulo-item_lista {
    color: $cor-info;
  }
}

.mensagem {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-radius: 6px;
  border: 1px dashed $consulta-cor-primaria;
  background: rgba($consulta-cor-primaria, 0.05);

  svg {
    margin-bottom: 8px;
    width: 24px !important;
    height: 24px;

    path {
      color: $consulta-cor-primaria;
    }
  }

  @include quebra-maior-que-telefone {
    margin-bottom: 16px;
    flex-direction: row;
    padding: 16px;

    svg {
      margin: 0 16px 0 0;
    }
  }
}

.mensagem__texto {
  @include fonte-regular;
  margin: 0;
  color: $cor-cinza-90;
  font-size: 15px;
  text-align: center;

  @include quebra-maior-que-telefone {
    font-size: 16px;
    text-align: left;
  }
}

.mensagem__link {
  color: $consulta-cor-primaria;
}

.u-mostrar-no-tablet {
  display: block;
}

.u-mostrar-apenas-no-tablet {
  display: block;
}