.carregando {
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 999999;

  &:before {
    content: "";
    margin-bottom: 16px;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    border: 3px solid $cor-cinza-10;
    border-left-color: rgba(0,0,0,.15);
    border-top-color: rgba(0,0,0,.15);
    border-radius: 50%;
    animation: animacao-carregando .6s linear infinite;
  }
}

.carregando__texto {
  cursor: default;
  @include fonte-regular;
  color: $cor-cinza-70;
}

.carregando_ativo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.83);

  &:before {
    display: block !important;
  }
}

@keyframes animacao-carregando {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(359deg);
  }
}