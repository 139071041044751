.criterios {
  margin: 16px 0 8px;

  @include quebra-maior-que-tablet {
    display: flex;
    justify-content: space-between;
  }
}

.criterios__grupo {
  + .criterios__grupo {
    margin-top: 16px;
  }

  @include quebra-maior-que-tablet {
    width: calc(50% - 16px);

    + .criterios__grupo {
      margin-top: 0;
    }
  }
}

.criterios__lista {
  display: flex;
  flex-direction: column;
}

.criterios__titulo {
  margin-bottom: 16px;
  color: $cor-cinza-90;
}

.criterios__item {
  display: flex;
  align-items: center;
  line-height: 1.3;

  + .criterios__item {
    margin-top: 16px;
  }
}

.criterio__item_atendido {
  color: $consulta-cor-primaria;
}

.criterio__item_nao-atendido {
  color: $cor-cinza-60;
}

.criterios__mensagem {
  margin: 0;
  color: $cor-cinza-60;
}

.criterios__descricao {
  margin: 0 0 0 8px;
}