@import "~digix-ui/ativos/sass/temas/habix-inscricao-compartilhada";

$consulta-cor-primaria: #31823D;

//Componentes
@import "../components/BlankSlate/styles.scss";
@import "../components/Dialogo/styles.scss";
@import "../components/Header/styles.scss";
@import "../components/Rodape/styles.scss";
@import "../components//SorteadoItem/styles.scss";
@import "../components/Loading/styles.scss";
@import "../components/ListaPaginada/styles.scss";
@import "../components/ListaPaginada/SeletorDePagina/styles.scss";
@import "../components/ListaDeCriterios/CriterioItem/styles.scss";

// Telas
@import "../telas/Home/styles.scss";
